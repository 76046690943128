// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { config } from ".";

// -----------------------------------------------
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigProd = {
  apiKey: "AIzaSyDi0rxZ0Er49aflM2ixVCiZ1Nzzmpulm1g",
  authDomain: "oneqr-flapx-prod.firebaseapp.com",
  projectId: "oneqr-flapx-prod",
  storageBucket: "oneqr-flapx-prod.appspot.com",
  messagingSenderId: "602373977660",
  appId: "1:602373977660:web:9b68d5399e69813313f539",
  measurementId: "G-9F6X483MYD"
};


const firebaseConfigStaging = {
  apiKey: "AIzaSyBHvSgZ3WvSeYpmbGundEf0ndwr9NzPoJA",
  authDomain: "stg-oneqr.firebaseapp.com",
  projectId: "stg-oneqr",
  storageBucket: "stg-oneqr.appspot.com",
  messagingSenderId: "161999362642",
  appId: "1:161999362642:web:a5c527db2361e2fe779d8b",
  measurementId: "G-E2FHBLL7JZ"
};

// -----------------------------------------------
// Initialize Firebase
export const app = config.env === 'prod' ? initializeApp(firebaseConfigProd) : initializeApp(firebaseConfigStaging);

// -----------------------------------------------
// Initialize Analytics
export const analytics = getAnalytics(app);

// -----------------------------------------------
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const isFirebaseMsgSupported = isSupported();

const vapIdKey = config.env === 'prod' ? 'BLfn1aIPExXvOiXS1Hep6WZWsHwmtcNrJmxuSj2L7ghsguOJE_FlILyksZgGr7aMMp1ht6CxCx6bnSKkPpqZ5rM' : 'BOMqxoMiJfQRUXiZuP-RcovOWzEm71N0kfg9A167DGa7nJfjW_HEkAwvnCKhm4CSEWCH79haAF58zNKwpJzLVDw';

export const fetchToken = () =>
  getToken(messaging, { vapidKey: vapIdKey }).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      return currentToken;
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    }
    console.log('No registration token available. Request permission to generate one.');
    return undefined;
    // shows on the UI that permission is required 
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// -----------------------------------------------


// -----------------------------------------------

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app)

remoteConfig.settings = {
  minimumFetchIntervalMillis: 500,
}

// fetch and activate values
export const userIdHashKey = fetchAndActivate(remoteConfig)
  .then(() => {
    // get remote config value
    const hashKey = getValue(remoteConfig, "hash_key").asString();
    // console.log('hashKey: ', JSON.stringify(hashKey))
    return hashKey;
  })
  .catch((err) => {
    console.log(err)
  });



