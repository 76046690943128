/*
TICKET HOOK
used in
1. src/components/hook-form/RHFUserSearch.js
*/
// import { useSnackbar } from 'notistack';
// import { useState } from "react";
// import { useNavigate } from 'react-router-dom';
// hooks
import useApi from '../api/useApi';
//
import { endpoints } from '../../configs/api';
// import { URLS } from '../../routes/paths';

// -----------------------------------------------

const useTicket = () => {
  // const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const { fetchResponse } = useApi();



  const searchUsers = async (searchTerm, groupId, facilityId, groupType = 'general') => {
    let group = true;
    if (groupId) {
      group = false;
    }
    const res = await fetchResponse(`${endpoints.users.find}search=${searchTerm}&group_id=${groupId}&group_type=${groupType}&facility_id=${facilityId}&group=${group}`);
    // console.log("Await", res);
    if (res.data && Array.isArray(res.data)) {
      return res.data;
    }

    return [];

  }

  const trackUser = async () => {

    const res = await fetchResponse(endpoints.users.track);

    if (res.error) {
      console.log(res.error)
    }

  }


  return {
    searchUsers,
    trackUser
  };
}

export default useTicket 